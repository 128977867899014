/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { ModelAccountState } from '@/store/modelAccount/types';
import { MyAccountMainInfo } from '@/components/pages/model-account/my-account-main-info/model-account-my-account-main-info.interface'
const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountMainInfoPage extends Vue {
	@State('modelAccount', {namespace}) modelAccount: ModelAccountState | undefined;
	@Action('updateProfileInformation', {namespace}) updateProfileInformation: any;
	@Action('getProfileInformation', {namespace}) getProfileInformation: any;
	@Getter('getModelSettingsEthnicOrigin', {namespace}) getModelSettingsEthnicOrigin: any;
	@Getter('getModelSettingsSexualPreferences', {namespace}) getModelSettingsSexualPreferences: any;
	@Getter('getModelSettingsNativeLanguage', {namespace}) getModelSettingsNativeLanguage: any;
	@Getter('getModelSettingsMoreLanguage', {namespace}) getModelSettingsMoreLanguage: any;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;
	@Getter('getModelSettingsAge', {namespace}) getModelSettingsAge: any;
	@Getter('getModelSettingsCountry', {namespace}) getModelSettingsCountry: any;
	@Getter('getModelSettingsCity', {namespace}) getModelSettingsCity: any;

	mainInfo: MyAccountMainInfo ={
		username: '',
		viewedAge: 0,
		ethnicOrigin: '',
		sexualPreferences: '',
		nativeLanguage: '',
		moreLanguage: '',
		country: '',
		hometown: ''
	}
	// @ts-ignore
	domElement = document.querySelector('.container').addEventListener('click', this.onSubmit);

	$refs!: {
		refEthnicOrigin: HTMLInputElement,
		refSexualPreferences: HTMLInputElement,
		refNativeLanguage: HTMLInputElement,
		refMoreLanguage: HTMLInputElement,
	}

	handleClickElement(e: Event) {
		const { target } = e;
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('ethnic-origin')) {
			this.$refs.refEthnicOrigin.value = (target as HTMLLIElement).innerText;
			this.mainInfo.ethnicOrigin = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('sexual-preferences')) {
			this.$refs.refSexualPreferences.value = (target as HTMLLIElement).innerText;
			this.mainInfo.sexualPreferences = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('native-language')) {
			this.$refs.refNativeLanguage.value = (target as HTMLLIElement).innerText;
			this.mainInfo.nativeLanguage = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('more-language')) {
			this.$refs.refMoreLanguage.value = (target as HTMLLIElement).innerText;
			this.mainInfo.moreLanguage = (target as HTMLLIElement).innerText;
		}
	}

	changeSelected() {
		document.addEventListener('click', this.handleClickElement);
	}

	onSubmit() {
		this.updateProfileInformation({
			username: this.mainInfo.username,
			viewedAge: this.mainInfo.viewedAge,
			ethnicOrigin: this.mainInfo.ethnicOrigin,
			sexualPreferences: this.mainInfo.sexualPreferences,
			nativeLanguage: this.mainInfo.nativeLanguage,
			moreLanguage: this.mainInfo.moreLanguage,
			country: this.mainInfo.country,
			hometown: this.mainInfo.hometown
		});
		document.removeEventListener('click',  this.handleClickElement);
	}

	mounted() {
		this.getProfileInformation();
		this.$refs.refEthnicOrigin.value = this.getModelSettingsEthnicOrigin;
		this.$refs.refSexualPreferences.value = this.getModelSettingsSexualPreferences;
		this.$refs.refNativeLanguage.value = this.getModelSettingsNativeLanguage;
		this.$refs.refMoreLanguage.value = this.getModelSettingsMoreLanguage;
		this.mainInfo = {
			username: this.getModelSettingsUsername,
			viewedAge: this.getModelSettingsAge,
			ethnicOrigin: this.getModelSettingsEthnicOrigin,
			sexualPreferences: this.getModelSettingsSexualPreferences,
			nativeLanguage: this.getModelSettingsNativeLanguage,
			moreLanguage: this.getModelSettingsMoreLanguage,
			country: this.getModelSettingsCountry,
			hometown: this.getModelSettingsCity
		}
	}
}
